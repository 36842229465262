import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
// import { Option } from "antd/lib/mentions";

const CREATE_UPDATE_LDAP_REMOTE = gql`
  mutation CreateOrUpdateLDAPRemote(
    $id: String
    $name: String
    $workspaceId: String!
    $base: String!
    $userDn: String!
    $password: String!
    $remoteUrl: String
    $credentialsSourceType: CredentialsSourceType!
    $propertiesFilePath: String!
  ) {
    createOrUpdateLDAPRemoteSystem(
      id: $id
      remoteSystem: {
        name: $name
        description: "remote created from graphql"
        workspaceId: $workspaceId
      }
      inputCredentials: { base: $base, userDn: $userDn, password: $password }
      remoteUrl: $remoteUrl
      credentialsSourceType: $credentialsSourceType
      propertiesFileCredsSource: { propertiesFilePath: $propertiesFilePath }
    ) {
      success
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

const GET_REMOTE_BY_ID = gql`
  query ($id: String!) {
    getRemoteSystemById(id: $id) {
      result {
        ... on LDAPRemoteSystem {
          id
          name
          description
          remoteUrl
          remoteSystemCredentials {
            base
            userDn
            password

            credentialsSource {
              sourceType
              ... on PropertiesFileCredsSource {
                propertiesFilePath
              }
            }
          }
        }
      }
      success
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

const RemoteForm = (props: any) => {
  const { form } = props;
  //   const credSrcType = Form.useWatch("credentialsSourceType", form);
  const [credSrcType, setCredSrcType] = useState("");
  const handleSrcType = (e: string) => setCredSrcType(e);

  useEffect(() => {
    const credentialsSourceType = form.getFieldValue("credentialsSourceType");
    setCredSrcType(credentialsSourceType);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="remoteSystemType"
            label="Remote System Type"
            rules={[
              {
                required: true,
                message: "Please select remote system type",
              },
            ]}
          >
            <Select
              placeholder="Select the change type"
              style={{ marginTop: "0", width: "100%" }}
              defaultValue={"LDAP Remote System"}
              value={"LDAP Remote System"}
            >
              <Select.Option value="LDAP Remote System" key="LDAPRemoteSystem">
                LDAP Remote System
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input placeholder="Please enter Remote name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="credentialsSourceType"
            label="Credentials Source Type"
            rules={[
              {
                required: true,
                message: "Please choose credentials source type",
              },
            ]}
          >
            <Select
              placeholder="Credentials Source Type"
              style={{ marginTop: "0", width: "100%" }}
              onChange={(e: string) => handleSrcType(e)}
            >
              <Select.Option value="DIRECT" key="DIRECT">
                Direct Credentials
              </Select.Option>
              <Select.Option value="PROPERTIES_FILE" key="PROPERTIES_FILE">
                Properties File
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="remoteUrl"
            label="Remote Url"
            rules={[{ required: true, message: "Please enter a Remote Url" }]}
          >
            <Input placeholder="Please enter Remote Url" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="userDn"
            label="UserDn"
            rules={[{ required: true, message: "Please enter UserDn" }]}
          >
            <Input placeholder="Please enter UserDn" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter Password" }]}
          >
            <Input placeholder="Please enter Password" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="base"
            label="Base"
            rules={[{ required: true, message: "Please enter Base" }]}
          >
            <Input placeholder="Please enter Base" />
          </Form.Item>
        </Col>
      </Row>

      {credSrcType === "PROPERTIES_FILE" ? (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="propertiesFilePath" label="Source properties file">
              <Input placeholder="Please enter source properties file " />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Form>
  );
};

export const CreateRemotesForm = (props: any) => {
  const {
    drawer,
    refetch,
    remoteId,
    setDrawer,
    setRemoteIdToUpdate,
    workspaceId,
  } = props;

  const [createRemoteGQL] = useMutation(CREATE_UPDATE_LDAP_REMOTE);
  const {
    loading,
    data,
    refetch: refetchById,
  } = useQuery(GET_REMOTE_BY_ID, {
    variables: { id: remoteId },
    skip: remoteId === null,
  });

  const [form] = Form.useForm();

  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const onClose = () => {
    form.resetFields();
    setRemoteIdToUpdate(null);
    setDrawer("hidden");
  };

  const onFinish = () => {
    setLoadingButton(true);
    const remoteDetails = {
      id: remoteId,
      name: form.getFieldValue("name"),
      workspaceId: workspaceId,
      base: form.getFieldValue("base"),
      userDn: form.getFieldValue("userDn"),
      password: form.getFieldValue("password"),
      remoteUrl: form.getFieldValue("remoteUrl"),
      credentialsSourceType: form.getFieldValue("credentialsSourceType"),
      propertiesFilePath: form.getFieldValue("propertiesFilePath") || "",
    };

    createRemoteGQL({
      variables: remoteDetails,
    })
      .then(() => {
        notification["success"]({
          message: "Success!",
          description: "Your Remote is Ready!",
        });
        setLoadingButton(false);
        form.resetFields();
        onClose();
        refetch();
        refetchById({ id: remoteDetails.id });
      })
      .catch(() => {
        notification["error"]({
          message: "Some Error Occured",
          description: "Some error occured while creating new Remote!",
        });
        //form.resetFields();
        setLoadingButton(false);
      });
  };

  const showDrawer = () => {
    setDrawer("visible");
  };

  if (loading)
    return (
      <div className="center-loader">
        <Spin size="large" />
      </div>
    );

  if (data?.getRemoteSystemById?.success) {
    const remoteDetails = data.getRemoteSystemById.result;
    const dataObj = {
      name: remoteDetails.name,
      base: remoteDetails.remoteSystemCredentials.base,
      userDn: remoteDetails.remoteSystemCredentials.userDn,
      password: remoteDetails.remoteSystemCredentials.password,
      remoteUrl: remoteDetails.remoteUrl,
      credentialsSourceType:
        remoteDetails.remoteSystemCredentials.credentialsSource.sourceType,
      propertiesFilePath:
        remoteDetails.remoteSystemCredentials.credentialsSource
          .propertiesFilePath,
    };
    form.setFieldsValue(dataObj);
  }

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        <PlusOutlined /> New Remote
      </Button>
      <Drawer
        title="Create a new Remote"
        width={720}
        onClose={onClose}
        visible={drawer === "visible"}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onFinish} type="primary" loading={loadingButton}>
              Save
            </Button>
          </div>
        }
      >
        <RemoteForm form={form} />
      </Drawer>
    </>
  );
};
